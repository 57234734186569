import { dropDownHelperFinancial, fieldType } from '../helper';

export const useFinancialsForm = () => {
    const amountOfInstallment = '€ 100.000';
    return {
        installment: {
            installmentsDetails: {
                noOfInstallments: 5,
                amountOfInstallments: amountOfInstallment,
                paidInstallments: [1, 0, 0, 0, 0],
                form: [
                    {
                        label: 'Installment',
                        value: amountOfInstallment,
                        type: fieldType.textField
                    },
                    {
                        label: 'Date',
                        type: fieldType.dateTime
                    },
                    {
                        label: null,
                        type: fieldType.selectField,
                        option: dropDownHelperFinancial
                    }
                ]
            },
            form: [
                [
                    {
                        label: 'Deed amount',
                        value: '€ 100.000',
                        type: fieldType.textField
                    },
                    {
                        label: 'Deed date',
                        type: fieldType.dateTime
                    },
                    {
                        label: null,
                        value: dropDownHelperFinancial[0].value,
                        type: fieldType.selectField,
                        option: dropDownHelperFinancial
                    }
                ],
                [
                    {
                        label: 'Reservation amount',
                        value: '€ 100.000',
                        type: fieldType.textField
                    },
                    {
                        label: 'Reservation date',
                        type: fieldType.dateTime
                    },
                    {
                        label: null,
                        value: dropDownHelperFinancial[0].value,
                        type: fieldType.selectField,
                        option: dropDownHelperFinancial
                    }
                ]
            ]
        },
        commissionNotes: [
            {
                label: 'Comission notes',
                value: 'Shared in 2/4',
                type: fieldType.textField
            }
        ]
    };
};
