import { call, put } from 'redux-saga/effects';
import { createSliceSaga } from 'store/saga-helper';
import { ClientsReducerAction } from './slice';
import { AxiosResponse } from 'axios';
import { CreateClient, DeleteClientById, GetAllCountries, GetClientDetailsById, GetClientsData, UpdateClient } from 'services/clients';
import { ConvertToQueryString } from 'utils/common';

const saga = createSliceSaga({
    name: 'clients-state',
    caseSagas: {
        *fetchClientsList(action: { payload: { [key: string]: any } }) {
            try {
                yield put(ClientsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetClientsData, ConvertToQueryString(action.payload));
                if (response?.status === 200) {
                    ClientsReducerAction.setClientsDetails([]);
                    ClientsReducerAction.setTotal(0);
                    yield put(ClientsReducerAction.setClientsDetails(response?.data));
                    yield put(ClientsReducerAction.setTotal(response?.data?.totalCount));
                }
            } catch (error) {
                console.log('Error in Clients API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsLoading(false));
            }
        },
        *createClient(action: { payload: { [key: string]: any } }) {
            try {
                yield put(ClientsReducerAction.setIsClientSavedLoading(true));
                const response: AxiosResponse = yield call(CreateClient, action.payload);
                if (response?.status === 200) {
                    ClientsReducerAction.setSelectedClient({});
                    yield put(ClientsSagaActions.fetchClientsList());
                }
            } catch (error) {
                console.log('Error in save Client API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsClientSavedLoading(false));
            }
        },
        *updateClient(action: { payload: { [key: string]: any } }) {
            try {
                yield put(ClientsReducerAction.setIsClientUpdateLoading(true));
                const response: AxiosResponse = yield call(UpdateClient, action.payload);
                if (response?.status === 200) {
                    yield put(ClientsSagaActions.fetchClientsList());
                }
            } catch (error) {
                console.log('Error in Update Client API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsClientUpdateLoading(false));
            }
        },
        *getClientById(action: { payload: string }) {
            try {
                const response: AxiosResponse = yield call(GetClientDetailsById, String(action.payload));
                if (response?.status === 200) {
                    ClientsReducerAction.setSelectedClient({});
                    yield put(ClientsReducerAction.setSelectedClient(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Get client by Id API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsLoading(false));
            }
        },
        *deleteClientDataById(action: { payload: string }) {
            try {
                yield put(ClientsReducerAction.setIsDeleteClientLoading(true));
                const response: AxiosResponse = yield call(DeleteClientById, action.payload);
                if (response?.status === 200) {
                    yield put(ClientsSagaActions.fetchClientsList());
                    yield put(ClientsReducerAction.setSelectedClient({}));
                }
            } catch (error) {
                console.log('Error in Delete Client API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsDeleteClientLoading(false));
            }
        },
        *getCountriesList() {
            try {
                const response: AxiosResponse = yield call(GetAllCountries);
                if (response?.status === 200) {
                    ClientsReducerAction.setCountriesList([]);
                    yield put(ClientsReducerAction.setCountriesList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Countries list API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsLoading(false));
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: ClientsSagaActions, saga: ClientsSaga, name: ClientsSagaName } = saga;
