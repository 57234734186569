import { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import color from 'assets/scss/_themes-vars.module.scss';
import { ClipAttachmentsIcon } from 'ui-component/Icons';
import './styles.scss';

const DocumentTabs = () => {
    const [files, setFiles] = useState<File[]>([]);
    const BGArray = ['#8B6F4E', '#5B4933'];

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 100,
        accept: {
            'application/pdf': []
        },
        onDrop: (acceptedFiles) => {
            const newFiles = (acceptedFiles as File[])?.map((file: any) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            );
            let temp = [];
            temp = [...newFiles, files?.length ? files : null].flat(Infinity).filter((n) => n);
            setFiles(temp);
        }
    });

    useEffect(() => {
        return () => files.forEach((file: any) => URL.revokeObjectURL(file?.preview));
    }, []);

    return (
        <Grid style={{ minHeight: 'calc(100vh - 478px)' }} item className="input-property-forms drop-zone-SJ" xs={12} md={12} marginTop={'40px'}>
            <Grid className="drop-zone-division" display={'flex'} flexDirection={'column'} gap={'20px'}>
                <Grid display={'flex'} gap={'10px'}>
                    <ClipAttachmentsIcon />
                    <Typography variant="h3">{files?.length} Attachments</Typography>
                </Grid>
                <Grid className="theGapClass" display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} width={'100%'} flexWrap={'wrap'}>
                    {files?.map((file: any) => (
                        <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            xl={2.84}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            className="propertyCardViewInnerCards"
                            flexWrap={'wrap'}
                            key={file?.name}
                        >
                            <div
                                className="thumbnails-division"
                                style={{
                                    display: 'flex',
                                    backgroundColor: BGArray[Math.floor(Math.random() * BGArray.length)],
                                    height: '25vh',
                                    borderRadius: '12px 12px 0px 0px'
                                }}
                            >
                                {/* <iframe
                                    style={{ height: '30vh', width: '100%', borderRadius: '12px 12px 0px 0px' }}
                                    className="doc"
                                    src={file.preview}
                                /> */}
                            </div>
                            <div
                                style={{
                                    padding: '12px',
                                    backgroundColor: color.secondaryLight,
                                    borderRadius: '0px 0px 12px 12px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    width: '100%'
                                }}
                            >
                                {file?.name}
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid item className="custom-drop-zone-container" xs={12} md={12}>
                    <Grid item className="custom-drop-zone" xs={12} md={12}>
                        <div {...getRootProps({ className: `${isDragActive ? 'drag-active' : ''} drop-zone` })}>
                            <input {...getInputProps()} />
                            <Button style={{ backgroundColor: color.primaryMain, color: 'white' }}>Add Documents</Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DocumentTabs;
