import { createSlice } from '@reduxjs/toolkit';
import { PropertiesDataType, PropertiesDetailsType, propertyUnitsListDataType, UnitModificationDataType } from './types';

export const initialState: PropertiesDetailsType = {
    propertyList: [],
    isDeleteModalOpen: false,
    isLoading: false,
    selectedProperty: {} as PropertiesDataType,
    propertyUnitsList: [],
    propertyUpdatesList: [],
    propertyDocumentsList: [],
    propertyGalleryList: [],
    propertyEditUnitsDetails: [],
    propertyDetails: {} as PropertiesDataType,
    propertyDetailGalleryImages: [],
    selectedPropertyUnit: {} as propertyUnitsListDataType,
    isAddPropertyUnitLoading: false,
    unitsFinancialsList: [],
    unitsModificationsList: [],
    selectedUnitModification: {} as UnitModificationDataType,
    page: 1,
    pageSize: 10,
    total: 0,
    sortBy: "",
    unitsPage: 1,
    unitsPageSize: 10,
    unitsTotal: 0
};

const properties = createSlice({
    name: 'propertiesState',
    initialState,
    reducers: {
        setPropertiesDetails(state, action) {
            state.propertyList = action.payload.data;
        },
        setToggleDeleteModal(state, action) {
            state.isDeleteModalOpen = action.payload;
        },
        setSelectedProperty(state, action) {
            state.selectedProperty = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setPropertyUnitsDetails(state, action) {
            state.propertyUnitsList = action.payload.data;
        },
        setPropertyUpdatesDetails(state, action) {
            state.propertyUpdatesList = action.payload.data;
        },
        setPropertyDocumentsList(state, action) {
            state.propertyDocumentsList = action.payload.data;
        },
        setPropertyGalleryList(state, action) {
            state.propertyGalleryList = action.payload;
        },
        setPropertyEditUnitsDetails(state, action) {
            state.propertyEditUnitsDetails = action.payload;
        },
        setPropertyDetails(state, action) {
            state.propertyDetails = action.payload;
        },
        setPropertyDetailGalleryImages(state, action) {
            state.propertyDetailGalleryImages = action.payload;
        },
        setSelectedPropertyUnit(state, action) {
            state.selectedPropertyUnit = action.payload;
        },
        setIsAddPropertyUnitLoading(state, action) {
            state.isAddPropertyUnitLoading = action.payload;
        },
        setUnitsFinancialsList(state, action) {
            state.unitsFinancialsList = action.payload;
        },
        setUnitModificationsList(state, action) {
            state.unitsModificationsList = action.payload;
        },
        setSelectedUnitModification(state, action) {
            state.selectedUnitModification = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotal(state, action) {
            state.total = action.payload;
        },
        setSortBy(state, action) {
            state.sortBy = action.payload;
        },
        setUnitsPage(state, action) {
            state.unitsPage = action.payload;
        },
        setUnitsPageSize(state, action) {
            state.unitsPageSize = action.payload;
        },
        setUnitsTotal(state, action) {
            state.unitsTotal = action.payload;
        }
    }
});

export const { actions: PropertiesReducerAction, name: PropertiesReducerName, reducer: PropertiesReducer } = properties;
