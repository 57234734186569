import { createSlice } from '@reduxjs/toolkit';
import { UpdatesDetailsType } from './types';
import { propertyUpdatesListDataType } from 'store/properties/types';

export const initialState: UpdatesDetailsType = {
    updatesListByUserId: [],
    isLoading: false,
    isDeleteUpdateLoading: false,
    isDeleteModalOpen: false,
    selectedUpdate: {} as propertyUpdatesListDataType,
    page: 1,
    pageSize: 10,
    total: 0,
    sortBy: ''
};

const updates = createSlice({
    name: 'updatesState',
    initialState,
    reducers: {
        setUpdatesListByUserId(state, action) {
            state.updatesListByUserId = action.payload.data;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setIsDeleteUpdateLoading(state, action) {
            state.isDeleteUpdateLoading = action.payload;
        },
        setToggleDeleteModal(state, action) {
            state.isDeleteModalOpen = action.payload;
        },
        setSelectedUpdate(state, action) {
            state.selectedUpdate = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotal(state, action) {
            state.total = action.payload;
        },
        setSortby(state, action) {
            state.sortBy = action.payload;
        }
    }
});

export const { actions: UpdatesReducerAction, name: UpdatesReducerName, reducer: UpdatesReducer } = updates;
