import { Typography } from '@mui/material';
import useConfig from 'hooks/useConfig';
import color from 'assets/scss/_themes-vars.module.scss';

export const SidebarConfig = ({ children }: any) => {
    const { fontFamily } = useConfig();
    return (
        <Typography fontFamily={fontFamily} fontSize={'14px'} fontWeight={600} lineHeight={'20px'} color={color.grey550}>
            {children}
        </Typography>
    );
};
