import { AxiosResponse } from 'axios';
import { DownloadFile, GetUsersByRole } from 'services/user';
import { createSliceSaga } from 'store/saga-helper';
import { UserReducerAction } from './slice';
import { call, put } from 'redux-saga/effects';

const saga = createSliceSaga({
    name: 'users-state',
    caseSagas: {
        *getUserByManagerRole() {
            try {
                const response: AxiosResponse = yield call(GetUsersByRole, { role: 'manager' });
                if (response?.status === 200) {
                    UserReducerAction.setManagersDetails([]);
                    yield put(UserReducerAction.setManagersDetails(response.data.data));
                }
            } catch (error) {
                console.log('Error in Fetch users by role API', error);
                throw new Error();
            }
        },
        *getUserByClientRole() {
            try {
                const response: AxiosResponse = yield call(GetUsersByRole, { role: 'client' });
                if (response?.status === 200) {
                    UserReducerAction.setClientsDetails([]);
                    yield put(UserReducerAction.setClientsDetails(response.data.data));
                }
            } catch (error) {
                console.log('Error in Fetch users by role API', error);
                throw new Error();
            }
        },
        *downloadFile(action: { payload: { [key: string]: any } }) {
            try {
                const response: { [key: string]: any } = yield call(DownloadFile, action.payload);
                const func = async () => {
                    if (response.ok) {
                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `${action.payload.fileName}.xlsx`; // You can set the file name here
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                };
                func();
            } catch (error) {
                console.log('Error in download data API', error);
                throw new Error();
            }
        }
    },
    sagatype: 'takeEvery'
});

export const { actions: UserSagaActions, saga: UserSaga, name: UserSagaName } = saga;
