import { Box } from '@mui/system';
import { Theme } from '@mui/material/styles';

import { BuilderIconDark, DevelopmentDark, DocumentsIconLight, GalleryLight } from 'ui-component/Icons';
import { TabsProps } from 'types';

export const test = () => <></>;

// tabs option
export const tabsOption = [
    {
        label: 'Profile',
        icon: BuilderIconDark
    },
    {
        label: 'Units',
        icon: DevelopmentDark
    },
    {
        label: 'Gallery',
        icon: GalleryLight
    },
    {
        label: 'Documents',
        icon: DocumentsIconLight
    }
];

export function TabPanel({ children, value, index, ...other }: TabsProps) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

export const tabStyle = (theme: Theme) => ({
    mb: 3,
    '& a': {
        minHeight: 'auto',
        minWidth: 10,
        py: 1.5,
        px: 1,
        // mr: 2.25,
        color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .MuiTabs-scroller': {
        height: 'fit-content'
    },
    '& a.Mui-selected': {
        color: theme.palette.primary.main
    },
    '& .MuiTabs-indicator': {
        bottom: 2
    },
    '& a > svg': {
        marginBottom: '0px !important',
        mr: 1.25
    },
    '& .MuiTabs-flexContainer': {
        border: 'none !important'
    }
});
