import { Grid, MenuItem, TextField } from '@mui/material';
import { useFinancialsForm } from './FormHooks/useFinancialsForm';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import './style.scss';
import { fieldType } from './helper';
import { usePriceAndAreaDetails } from './FormHooks/usePriceAndAreaDetails';

const FinancialTabs = () => {
    const { commissionNotes, installment } = useFinancialsForm();
    const { priceDetails } = usePriceAndAreaDetails();
    const { noOfInstallments, form, paidInstallments } = installment.installmentsDetails;

    return (
        <Grid key={Math.random()} container className="input-property-forms financial-forms" xl={12} lg={12}>
            {priceDetails.map((formData) => {
                return (
                    <Grid key={Math.random()} container className="input-property-forms" xl={12} lg={12}>
                        {formData.map(({ type, ...data }) => {
                            switch (type) {
                                case fieldType.textField:
                                    return (
                                        <Grid
                                            key={Math.random()}
                                            item
                                            className="input-property-forms"
                                            marginBottom={'20px'}
                                            paddingRight={{ xs: '0px', md: '10px' }}
                                            xs={12}
                                            md={6}
                                            lg={3}
                                            xl={1.5}
                                        >
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...data}
                                            />
                                        </Grid>
                                    );
                                default:
                                    break;
                            }
                        })}
                    </Grid>
                );
            })}
            {installment.form.map((data) => (
                <Grid
                    key={Math.random()}
                    container
                    className="input-property-forms"
                    width={'100%'}
                    xl={12}
                    lg={12}
                    spacing={{ xs: 0, sm: 1 }}
                >
                    {data.map(({ type, ...data }) => {
                        switch (type) {
                            case fieldType.textField:
                                return (
                                    <Grid key={Math.random()} item className="input-property-forms" marginBottom={'20px'} xs={12} sm={4}>
                                        <TextField sx={{ width: '100%' }} {...data} />
                                    </Grid>
                                );
                            case fieldType.selectField:
                                return (
                                    <Grid key={Math.random()} item className="input-property-forms" marginBottom={'20px'} xs={12} sm={4}>
                                        <TextField
                                            id="outlined-select-language"
                                            select
                                            fullWidth
                                            value={data.value}
                                            sx={{ width: '100%' }}
                                            onChange={() => {}}
                                        >
                                            {data?.option?.length
                                                ? data?.option.map((option) => (
                                                      <MenuItem key={option.value} value={option.value}>
                                                          {option.label}
                                                      </MenuItem>
                                                  ))
                                                : null}
                                        </TextField>
                                    </Grid>
                                );
                            case fieldType.dateTime:
                                return (
                                    <Grid key={Math.random()} item marginBottom={'20px'} xs={12} sm={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={dayjs(new Date())}
                                                sx={{ width: '100%' }}
                                                label={data.label}
                                                format="ddd MMM DD YYYY"
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                );
                            default:
                                break;
                        }
                    })}
                </Grid>
            ))}
            <Grid key={Math.random()} container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                {[...Array(noOfInstallments).keys()].map((_, index) => {
                    return (
                        <Grid key={Math.random()} container className="input-property-forms" width={'100%'} spacing={{ xs: 0, sm: 1 }}>
                            {form.map(({ type, ...data }) => {
                                switch (type) {
                                    case fieldType.textField:
                                        return (
                                            <Grid key={Math.random()} item marginBottom={'20px'} xs={12} sm={4}>
                                                <TextField sx={{ width: '100%' }} {...data} />
                                            </Grid>
                                        );
                                    case fieldType.selectField:
                                        return (
                                            <Grid
                                                key={Math.random()}
                                                item
                                                className="input-property-forms"
                                                marginBottom={'20px'}
                                                xs={12}
                                                sm={4}
                                            >
                                                <TextField
                                                    id="outlined-select-language"
                                                    select
                                                    fullWidth
                                                    value={paidInstallments[index] ? 0 : 1}
                                                    sx={{ width: '100%' }}
                                                    onChange={() => {}}
                                                >
                                                    {data?.option?.length
                                                        ? data?.option.map((option) => (
                                                              <MenuItem key={option.value} value={option.value}>
                                                                  {option.label}
                                                              </MenuItem>
                                                          ))
                                                        : null}
                                                </TextField>
                                            </Grid>
                                        );
                                    case fieldType.dateTime:
                                        return (
                                            <Grid key={Math.random()} item marginBottom={'20px'} xs={12} sm={4}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={dayjs(new Date())}
                                                        sx={{ width: '100%' }}
                                                        format="ddd MMM DD YYYY"
                                                        label={data.label}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        );
                                    default:
                                        break;
                                }
                            })}
                        </Grid>
                    );
                })}
            </Grid>
            {commissionNotes.map(({ type, ...data }) => {
                switch (type) {
                    case fieldType.textField:
                        return (
                            <Grid key={Math.random()} paddingRight={{ xs: '0px', sm: '7px' }} marginBottom={'20px'} xs={12} item>
                                <TextField sx={{ width: '100%' }} {...data} />
                            </Grid>
                        );
                    default:
                        break;
                }
            })}
        </Grid>
    );
};

export default FinancialTabs;
