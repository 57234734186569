import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// project import
import Loadable from 'ui-component/Loadable';
import GuestGuard from 'routes/route-guard/GuestGuard';

const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));

// ==============================|| ROUTING RENDER ||============================== //
const router = createBrowserRouter(
    [
        {
            path: '/',
            element: (
                <GuestGuard>
                    <AuthLogin />
                </GuestGuard>
            )
        },
        AuthenticationRoutes,
        LoginRoutes,
        MainRoutes
    ],
    {
        basename: process.env.REACT_APP_BASE_NAME
    }
);

export default router;
