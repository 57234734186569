import { createRoot } from 'react-dom/client';

// third party
import { Provider } from 'react-redux';
// TODO: Maybe required in future
// import { PersistGate } from 'redux-persist/integration/react';

// project imports
import App from 'App';
import { store } from 'store';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';

// style + assets
import 'assets/scss/style.scss';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// google-fonts
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

// ==============================|| REACT DOM RENDER ||============================== //

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <Provider store={store}>
        {/* MAYBE REQUIRED IN FUTURE */}
        {/* <PersistGate loading={null} persistor={persister}> */}
        <ConfigProvider>
            <App />
        </ConfigProvider>
        {/* </PersistGate> */}
    </Provider>
);

reportWebVitals();
