import { ImageRequestPayloadType } from 'services/imageUpload/imageUploadServices';
import axiosServices from 'utils/axios';

export const GetPropertyEditUnitsDetailsById = async (id: string) => {
    try {
        const response = await axiosServices.get(`/units/properties-units/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const AddPropertyDetails = async (data: any) => {
    try {
        const response = await axiosServices.post(`/properties/saveProperty`, data);
        console.log('response', response);
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdatePropertyDataById = async (id: string, data: any) => {
    try {
        const response = await axiosServices.put(`/properties/update-property-details/${id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};
export const DeletePropertyUnit = async (id: string) => {
    try {
        return await axiosServices.delete(`/units/delete-units/${id}`);
    } catch (error) {
        return error;
    }
};

export const GetPropertyGallery = async (propertyId: any) => {
    try {
        return await axiosServices.get(`/properties/gallery/${propertyId}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        return error;
    }
};
export const AddEditGalleryImages = async (payload: ImageRequestPayloadType) => {
    try {
        const response = await axiosServices.post(`/properties/gallery`, payload);
        return response;
    } catch (error) {
        throw error;
    }
};
