import axiosServices from 'utils/axios';

export const GetUsersByRole = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/user/user-by-roles', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const DownloadFile = async (data: { [key: string]: any }) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/download`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response;
    } catch (error) {
        return error;
    }
};
