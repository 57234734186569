import { styled } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { IconCircleCheck, IconSquareRoundedX, IconInfoCircle, IconAlertCircle } from '@tabler/icons-react';
import { snackbarStateSelector } from 'store/snackbar/selector';
import { useSelector } from 'react-redux';

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
    '&.notistack-MuiContent-default': {
        backgroundColor: theme.palette.primary.main
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: theme.palette.error.main
    },
    '&.notistack-MuiContent-success': {
        backgroundColor: theme.palette.success.main
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: theme.palette.info.main
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: theme.palette.warning.main
    }
}));

// ===========================|| SNACKBAR - NOTISTACK ||=========================== //

const Notistack = ({ children }: any) => {
    const snackbar = useSelector(snackbarStateSelector);
    const iconSX = { marginRight: 8, fontSize: '1.15rem' };

    return (
        <StyledSnackbarProvider
            maxSnack={snackbar.maxStack}
            dense={snackbar.dense}
            iconVariant={
                snackbar.iconVariant === 'useemojis'
                    ? {
                          success: <IconCircleCheck style={iconSX} />,
                          error: <IconSquareRoundedX style={iconSX} />,
                          warning: <IconInfoCircle style={iconSX} />,
                          info: <IconAlertCircle style={iconSX} />
                      }
                    : undefined
            }
            hideIconVariant={snackbar.iconVariant === 'hide' ? true : false}
        >
            {children}
        </StyledSnackbarProvider>
    );
};

export default Notistack;
