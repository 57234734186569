import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, IconButton } from '@mui/material';

type ConfirmationModalProps = {
    title: string;
    isOpen: boolean;
    handleClose: Function;
    handleConfirm: Function;
    cancelButtonText?: string;
    confirmButtonText?: string;
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
    return (
        <Dialog sx={{ padding: '6px !important' }} open={props.isOpen} maxWidth="sm" fullWidth>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box paddingLeft="8px" fontSize="14px">
                    {props.title}
                </Box>
                <Box>
                    <IconButton onClick={() => props.handleClose()}>
                        <Close fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={() => props.handleClose()}>
                    {props.cancelButtonText ?? 'Cancel'}
                </Button>
                <Button color="primary" variant="contained" onClick={() => props.handleConfirm()}>
                    {props.confirmButtonText ?? 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;
