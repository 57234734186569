import { AxiosResponse } from 'axios';
import axiosServices from 'utils/axios';

export type ImageUploadResponse = {
    imageUrls: ImageRequestPayloadType[];
};

export type ImageResponseType = {
    imageUrl: string;
    name: string;
};

export type ImageRequestPayloadType = {
    property_id: string;
    images: ImageResponseType[];
};

export const singleImageUpload = async (payload: File): Promise<AxiosResponse<ImageUploadResponse>> => {
    try {
        const formData = new FormData();
        formData.append('image', payload);
        const response = await axiosServices.post('/upload-single', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const multipleImageUpload = async (payload: File[]): Promise<AxiosResponse<ImageUploadResponse>> => {
    try {
        const formData = new FormData();

        for (let i = 0; i < payload.length; i++) {
            formData.append(`images`, payload[i]);
        }

        const response = await axiosServices.post(`/upload-multiple`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};
