import { dropDownHelper, fieldType } from '../helper';

export const usePriceAndAreaDetails = () => {
    return {
        priceDetails: [
            [
                {
                    label: 'Pre-launch Price',
                    value: '€ 800. 000',
                    type: fieldType.textField
                },
                {
                    label: 'Closing Price',
                    value: '€ 820. 000',
                    type: fieldType.textField
                },
                {
                    label: 'Comission %',
                    value: '%5',
                    type: fieldType.textField
                },
                {
                    label: 'Comission total',
                    value: '€ 41. 000',
                    type: fieldType.textField
                },
                {
                    label: 'Comission + 23% IVA',
                    value: '€ 50. 430',
                    type: fieldType.textField
                },
                {
                    label: 'Value net',
                    value: '€ 779.000',
                    type: fieldType.textField
                },
                {
                    label: 'Paid value',
                    value: '€ 120.000',
                    type: fieldType.textField
                },
                {
                    label: 'Pending value',
                    value: '€ 700.000',
                    type: fieldType.textField
                }
            ]
        ],
        areaDetails: [
            [
                {
                    label: 'Usable Area ',
                    value: '140',
                    type: fieldType.textField
                },
                {
                    label: 'Private Gross Area',
                    value: '100',
                    type: fieldType.textField
                },
                {
                    label: 'Total Gross Area',
                    value: '240',
                    type: fieldType.textField
                },
                {
                    label: 'Área Total',
                    value: '240',
                    type: fieldType.textField
                },
                {
                    label: 'M2 Value',
                    value: '240',
                    type: fieldType.textField
                }
            ]
        ],
        facilityDetails: [
            [
                {
                    label: null,
                    value: dropDownHelper[5].value,
                    type: fieldType.selectField,
                    option: dropDownHelper
                },
                {
                    label: null,
                    value: dropDownHelper[6].value,
                    type: fieldType.selectField,
                    option: dropDownHelper
                }
            ]
        ]
    };
};
